@keyframes default-card-item--0 {
  100% {
    transform: translateX(calc(0% + 312px));
  }
}
@keyframes default-card-item--1 {
  100% {
    transform: translateX(calc(0% + 208px));
  }
}
@keyframes default-card-item--2 {
  100% {
    transform: translateX(calc(0% + 104px));
  }
}
@keyframes default-card-item--3 {
  100% {
    transform: translateX(calc(0% + 0px));
  }
}
@keyframes default-card-item--4 {
  100% {
    transform: translateX(calc(0% + -104px));
  }
}
@keyframes default-card-item--5 {
  100% {
    transform: translateX(calc(0% + -208px));
  }
}
@keyframes default-card-item--6 {
  100% {
    transform: translateX(calc(0% + -312px));
  }
}

@keyframes final-card {
  100% {
    transform: translateX(0);
  }
}
