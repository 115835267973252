@keyframes drop-top-left-x {
  100% {
    transform: translateX(calc(0% + 312px));
  }
}

@keyframes drop-top-center-x {
  100% {
    transform: translateX(calc(0% + 104px));
  }
}

@keyframes drop-right-x {
  100% {
    transform: translateX(calc(0% - 416px));
  }
}

@keyframes drop-left-x {
  100% {
    transform: translateX(calc(0% + 1040px));
  }
}

@keyframes drop-top-onethird-right-x {
  100% {
    transform: translateX(calc(0% - 104px));
  }
}

@keyframes drop-top-onethird-left-x {
  100% {
    transform: translateX(calc(0% + 416px));
  }
}

@keyframes drop-top-y {
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes drop-middle-y {
  100% {
    transform: translateY(-52%);
    opacity: 0;
  }
}

@keyframes drop-side-top-y {
  100% {
    transform: translateY(10%);
    opacity: 0;
  }
}

@keyframes drop-side-bottom-y {
  100% {
    transform: translateY(-150%);
    opacity: 0;
  }
}

