@keyframes default-card {
  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
    z-index: 1;
  }
}
