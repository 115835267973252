@import './styles/animations/index.scss';
@import-normalize;

* {
    box-sizing: border-box;
}

body,html{
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 100%;
}

body{
    font-family:'Nunito',serif;
    font-size:1em;
    overflow:hidden;
}

/* remove outline on focus */
textarea:focus, input:focus, button:focus{
    outline: none;
}

/* remove selection*/
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
